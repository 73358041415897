<template>
  <div class="login">
    <!-- 轮播 -->
    <div class="carousel">
      <div class="carousel-main">
        <img class="logo" src="@/assets/images/logo-white.png" />
        <el-carousel indicator-position="outside" arrow="never" trigger="click">
          <el-carousel-item v-for="item in carouselList" :key="item">
            <p>{{ item }}</p>
          </el-carousel-item>
        </el-carousel>
        <img class="earth" src="https://wjj-core.oss-cn-beijing.aliyuncs.com/earth.png" />
      </div>
    </div>
    <!-- 表单 -->
    <div class="from">
      <!-- 切换登录注册 -->
      <div class="from-type">
        <span>想起密码？</span>
        <el-button round @click="changeLoginType">登录</el-button>
      </div>
      <!-- 中间 -->
      <div class="card-box">
        <div class="title-box">
          <div class="title1">
            <span></span>
            <div>Welcome</div>
            <span></span>
          </div>
        </div>

        <div class="inputs" v-if="forgetPasswordType === 1">
          <div class="input-box">
            <input
              type="text"
              v-model="logonName"
              placeholder="请输入邮箱账号"
              maxlength="1000"
            />
            <img
              class="left"
              src="@/assets/images/home/account.png"
              alt="图片"
            />
          </div>
          <div class="input-box code">
            <input
              placeholder="请输入验证码"
              v-model="captchaValue"
              maxlength="4"
            />
            <img
              class="left"
              src="@/assets/images/home/shield.png"
              alt="图片"
            />
            <img
              class="security-code"
              @click="getCaptcha"
              :src="captchaImg"
              alt="图片"
            />
          </div>
        </div>
        <div class="inputs" v-if="forgetPasswordType === 2">
          <div class="input-box">
            <input
              type="text"
              v-model="validationCode"
              placeholder="请输入邮箱验证码"
              maxlength="4"
            />
            <img
              class="left"
              src="@/assets/images/home/account.png"
              alt="图片"
            />
          </div>
          <div class="input-box lock">
            <input
              :type="password1"
              placeholder="请输入新密码"
              v-model="newPassword"
              maxlength="11"
            />
            <img class="left" src="@/assets/images/home/lock.png" alt="图片" />
            <img
              class="right"
              :src="imgurl1"
              @click="changeEye(1)"
              alt="图片"
            />
          </div>
          <div class="input-box lock">
            <input
              :type="password2"
              placeholder="请确认输入密码"
              v-model="confirmPassword"
              maxlength="11"
            />
            <img class="left" src="@/assets/images/home/lock.png" alt="图片" />
            <img
              class="right"
              :src="imgurl2"
              @click="changeEye(2)"
              alt="图片"
            />
          </div>
        </div>
        <el-button @click="getResetPwdCode" v-if="forgetPasswordType === 1"
          >获取邮箱验证码</el-button
        >
        <el-button @click="setResetPassworde" v-if="forgetPasswordType === 2"
          >提交</el-button
        >
      </div>
      <div class="copyright" @click="jumpOut">
        Copyright ©2016-2023 南京百数来信息科技有限公司 苏ICP备17022236号-3
      </div>
    </div>
  </div>
</template>
<script>
import { getCaptcha, getResetPwdCode, setResetPassworde } from "@/api/user";
export default {
  data() {
    return {
      carouselList: ["自助市场调查", "流量变现", "速度快、质量高、价格低"],
      forgetPasswordType: 1, //分步
      password1: "password",
      imgurl1: require("@/assets/images/home/eye-close.png"),
      password2: "password",
      imgurl2: require("@/assets/images/home/eye-close.png"),
      agreeImg: require("@/assets/images/home/agree.png"),
      disAgreeImg: require("@/assets/images/home/disagree.png"),
      logonName: "", //邮箱
      captchaId: "", //图形验证码id
      captchaValue: "", //图形验证码值
      captchaImg: "",
      newPassword: "", //新密码
      confirmPassword: "", //确认密码
      validationCode: "", //邮箱验证码
    };
  },
  mounted() {
    this.getCaptcha();
  },
  methods: {
    //切换登录注册
    changeLoginType() {
      this.$router.push("/login?loginType=3");
    },
    //点击眼睛
    changeEye(type) {
      switch (type) {
        case 1:
          if (this.password1 === "password") {
            this.password1 = "text";
            this.imgurl1 = require("@/assets/images/home/eye-open.png");
          } else {
            this.password1 = "password";
            this.imgurl1 = require("@/assets/images/home/eye-close.png");
          }
          break;
        case 2:
          if (this.password2 === "password") {
            this.password2 = "text";
            this.imgurl2 = require("@/assets/images/home/eye-open.png");
          } else {
            this.password2 = "password";
            this.imgurl2 = require("@/assets/images/home/eye-close.png");
          }
          break;
      }
    },
    //获取图形验证码
    getCaptcha() {
      getCaptcha().then((res) => {
        this.captchaImg = "data:image/png;base64," + res.data.base64;
        this.captchaId = res.data.id;
      });
    },
    //获取邮箱验证码
    getResetPwdCode() {
      console.log(12344);
      const params = {
        name: this.logonName,
        captchaId: this.captchaId,
        captcha: this.captchaValue,
      };
      getResetPwdCode(params).then((res) => {
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "success",
          });
          this.forgetPasswordType = 2;
        }
      });
    },
    //重置密码
    setResetPassworde() {
      const params = {
        email: this.logonName,
        validationCode: this.validationCode,
        newPassword: this.newPassword,
        confirmPassword: this.confirmPassword,
      };
      setResetPassworde(params).then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "success",
          });
          this.$router.push("/login?loginType=3");
        }
      });
    },
    //跳出
    jumpOut() {
      window.open("https://beian.miit.gov.cn");
    },
    //忘记密码
    forgetPassword() {
      this.$router.push("/forgetPassword");
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  width: 100%;
  min-height: 100vh;
  display: flex;
  background-color: #f5f6fc;
  cursor: pointer;
  .carousel {
    width: 400px;
    min-height: 100vh;
    .carousel-main {
      background: linear-gradient(151deg, #4578ff 0%, #425ccf 100%);
      height: 100%;
      .logo {
        width: 90px;
        height: 40px;
        margin-top: 28px;
        margin-left: 35px;
      }
      p {
        font-size: 19px;
        color: #fff;
        text-align: center;
        margin-top: 110px;
      }
      .earth {
        width: 360px;
        height: 360px;
        margin: 50px 20px;
      }
    }
  }
  .from {
    position: relative;
    width: calc(100% - 400px);
    min-height: 700px;
    .from-type {
      width: 100%;
      margin-top: 44px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      span {
        font-size: 14px;
        color: #232323;
      }
      .el-button {
        padding: 0;
        width: 75px;
        height: 30px;
        line-height: 30px;
        color: #232323;
        cursor: pointer;
        margin-right: 50px;
        margin-left: 10px;
      }
      .el-button:hover {
        color: #409eff;
      }
    }
    .copyright {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 30px;
      font-size: 12px;
      color: #232323;
      word-spacing: 7px;
    }
  }
}
.card-box {
  position: absolute;
  top: 210px;
  left: 50%;
  transform: translateX(-50%);
  width: 420px;
  background: #ffffff;
  border-radius: 4px;
  .title-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 48px;
    .title1 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      span {
        width: 60px;
        height: 1px;
        background-color: #4578ff;
      }
      div {
        font-size: 30px;
        color: #4578ff;
        margin: 0 21px;
      }
    }
    .title2 {
      margin-top: 20px;
    }
  }
  .el-button {
    padding: 0;
    height: 50px;
    width: 350px;
    background-color: #4578ff;
    color: #fff;
    margin-left: 35px;
    font-size: 13px;
    margin-top: 20px;
    margin-bottom: 50px;
  }
}
.inputs {
  padding: 0 35px;
  .title {
    font-size: 12px;
    color: #06052b;
    margin-top: 30px;
    span {
      color: #e52323;
    }
  }
  .input-box {
    position: relative;
    .left {
      position: absolute;
      width: 22px;
      height: 22px;
      left: 15px;
      top: 29px;
    }
    .right {
      position: absolute;
      width: 22px;
      height: 22px;
      right: 15px;
      top: 29px;
      cursor: pointer;
    }
  }
  .lock {
    input {
      width: calc(100% - 106px);
      padding-left: 52px;
      padding-right: 52px;
    }
  }
  .code {
    input {
      width: 118px;
    }
    .security-code {
      position: absolute;
      top: 20px;
      right: 0;
      width: 131px;
      height: 41.6px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}
</style>
<style scoped>
.carousel /deep/ .el-carousel__container {
  height: 150px;
  min-height: 10px;
}
.carousel /deep/ .el-carousel__indicators {
  position: absolute;
  left: 50%;
  bottom: 50px;
  transform: translateX(-50%);
  display: none;
}
.carousel /deep/ .el-carousel__indicator {
  margin-right: 20px;
}
.carousel /deep/ .el-carousel__button {
  width: 24px;
  height: 8px;
  border-radius: 4px;
  background-color: #fff;
}

input {
  width: calc(100% - 54px);
  height: 40px;
  border: 1px solid #cfd0d3;
  font-size: 14px;
  margin-top: 20px;
  border-radius: 4px;
  padding-left: 52px;
  color: #232323;
}

input:focus {
  border-color: #4578ff;
}
</style>